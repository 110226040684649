import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';

import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import {
  BasicAuthInterceptor,
  ErrorInterceptor,
} from './interceptors/provider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiMockModule } from '@ng-stack/api-mock';
import { IS_BACKEND_MIXED, IS_PRODUCTION } from 'src/environments/environment';
import { ApiMockBackendService } from './mockBackend/api-mock-backend.service';

const apiMockModule = ApiMockModule.forRoot(ApiMockBackendService, {
  passThruUnknownUrl: IS_BACKEND_MIXED,
  cacheFromLocalStorage: false,
  delay: 500,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    !IS_PRODUCTION ? apiMockModule : [],
    GoogleMapsModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatMomentDateModule,
  ],
  providers: [
    GoogleMap,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

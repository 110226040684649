import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class IsNotRegisteredGuard implements CanActivate {

  user: User;
  token: string;

  constructor(private authenticationService:AuthenticationService, private router:Router, private route:ActivatedRoute) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.token = this.authenticationService.getToken();
      this.user = this.authenticationService.getUser();
      console.log(this.token);
      console.log(this.user);
     
      if(this.token && !this.user){
        return true;
      }
      else if (this.token && this.user){
        this.router.navigate(['/app'], { relativeTo: this.route });
        return false;
      }
      else{
        this.router.navigate(['/login/id'], { relativeTo: this.route });
        return false;
      }
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { API, HEADERS } from './constants';
import { User } from '../models/user';
import { Role } from '../models/role';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private user: BehaviorSubject<User>;
  private token: BehaviorSubject<string>;
  private id: BehaviorSubject<string>;
  private isIdSent: BehaviorSubject<boolean>;
  private activeRole: BehaviorSubject<Role>;

  constructor(private http: HttpClient) {

    this.token = new BehaviorSubject<string>(sessionStorage.getItem('token'));
    this.user = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.id = new BehaviorSubject<string>(sessionStorage.getItem('id'));
    const activeRole = this.user.getValue() && this.user.getValue().roles[0];
    this.activeRole = new BehaviorSubject<Role>(activeRole);
    this.isIdSent = new BehaviorSubject<boolean>(false);

    console.log(this.user.getValue())
  }

  setIsIdSent(isIdSent: boolean) {
    this.isIdSent.next(isIdSent);
  }

  getIsIdSent(): boolean {
    return this.isIdSent.getValue();
  }

  setId(id: string) {
    this.id.next(id);
  }

  getId(): string {
    return this.id.getValue();
  }

  setUser(user: User) {
    this.user.next(user);
    sessionStorage.setItem('currentUser', JSON.stringify(user))
  }

  getUser(): User {
    return this.user.getValue();
  }

  getUserObservable(): Observable<User> {
    return this.user.asObservable();
  }

  setToken(token: string) {
    this.token.next(token);
    sessionStorage.setItem("token", token)
  }

  getToken(): string {
    return this.token.getValue();
  }

  setActiveRole(activeRole: Role) {
    this.activeRole.next(activeRole);
  }

  getActiveRole(): Observable<Role> {
    return this.activeRole.asObservable();
  }

  getActiveRoleValue(): Role {
    return this.activeRole.getValue();
  }

  requestVerify(identity: string): Observable<any> {
    return this.http.post(
      API + '/verify',
      {
        "identity": identity
      },
      {
        headers: HEADERS,
        observe: 'response'
      }
    );
  }

  requestAuthentication(identity: string, code: string, storeToken:boolean=true): Observable<any> {
    return this.http.put(
      API + '/verify',
      {
        "identity": identity,
        "code": code
      },
      {
        headers: HEADERS,
        observe: 'response'
      }
    )
  }

  requestGetRenew(): Observable<any> {
    return this.http.get(
      API + '/verify/renew',
      {
        headers: HEADERS,
        observe: 'response'
      }
    );

  }

  requestGetUser(): Observable<any> {
    return this.http.get(
      API + '/account',
      {
        headers: HEADERS,
        observe: 'response'
      }
    ).pipe(
      map(response => {
        const userDto: any = response.body;
        console.log(userDto);

        const user: User = {
          firstName: userDto.firstname,
          lastName: userDto.lastname,
          mobile: userDto.mobile,
          email: userDto.email,
          country: userDto.country,
          postalcode: userDto.postalcode,
          address: userDto.address,
          dob: userDto.dob,
          marketing: userDto.marketing || [],
          disability: userDto.disability || [],
          roles: userDto.roles || [],
        }

        return user;
      })
    );
  }

  requestSendRegistrationData(token: string,
    mobile: string,
    firstName: string,
    lastName: string,
    postcode: string,
    country: string,
    address: string,
    dob: string,
    marketing: string[],
    disability: string[],
    oldbasisid: number): Observable<any> {

    let body = {
      "oldbasisid": oldbasisid,
      "mobile": mobile,
      "token": token,
      "firstname": firstName,
      "lastname": lastName,
      "postalcode": postcode,
      "country": country,
      "address": address,
      "dob": dob,
      "marketing": marketing,
      "disability": disability
    }

    Object.keys(body).forEach(key => !body[key] ? delete body[key] : null);

    console.log(body);

    return this.http.post(
      API + '/account',
      body,
      {
        headers: HEADERS,
        observe: 'response'
      }
    );
  }

  requestUpdateRegistrationData(token?: string,
    mobile?: string,
    firstName?: string,
    lastName?: string,
    postcode?: string,
    country?: string,
    address?: string,
    email?: string,
    emailtoken?: string,
    dob?: string,
    marketing?: string[],
    disability?: string[]): Observable<any> {

    let body = {
      "mobile": mobile,
      "token": token,
      "firstname": firstName,
      "lastname": lastName,
      "postalcode": postcode,
      "country": country,
      "address": address,
      "email": email,
      "emailtoken": emailtoken,
      "dob": dob,
      "marketing": marketing,
      "disability": disability
    }

    Object.keys(body).forEach(key => !body[key] ? delete body[key] : null);

    console.log(body)

    return this.http.put(
      API + '/account',
      body,
      {
        headers: HEADERS,
        observe: 'response'
      }
    );
  }

  logout() {
    this.setToken(null)
    sessionStorage.removeItem('token');
    this.setUser(null)
    sessionStorage.removeItem('currentUser');
    this.setId(null)
    sessionStorage.removeItem('id');
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(operation)
      console.error(error);
      return of(result as T);
    };
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestingComponent } from './components/UI/atoms/testing/testing.component';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { IsNotRegisteredGuard } from './guards/is-not-registered.guard';
import { IsUserLoginGuard } from './guards/is-user-login.guard';

const routes: Routes = [
  { path: 'testing', component: TestingComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', canActivate: [IsUserLoginGuard], loadChildren: () => import('./components/pages/public/login-page/login-page.module').then(m => m.LoginPageModule) },
  { path: 'registration', canActivate: [IsNotRegisteredGuard], loadChildren: () => import('./components/pages/private/registration-wizard-page/registration-wizard-page.module').then(m => m.RegistrationWizardPageModule) },
  { path: 'app', canActivate: [IsAuthenticatedGuard],loadChildren: () => import('./components/pages/private/main-app-page/main-app-page.module').then(m => m.MainAppPageModule) },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


//canActivate: [IsNotAuthenticatedGuard],
//, canActivate: [IsUserIdLoginPageGuard]
// , canActivate: [IsUserIdLoginPageGuard]